import { Component } from 'react';

export class Card extends Component {
    constructor(props) {
      super(props);
      this.state = {
        canCollapse: this.props.canCollapse ? true : false,
        collapsed: this.props.canCollapse && this.props.initialCollapsed ? true : false
      }
    }
  
    getCollapseStyle() {
      if(this.state.collapsed) {
        return "collapse-indicator";
      }
      return "collapse-indicator rotate"
    }
  
    toggleCollapse() {
      this.setState({
        collapsed: !this.state.collapsed
      });
    }
  
    renderCollapse() {
      if(this.state.canCollapse) {
        return <span className={this.getCollapseStyle()}>⌃</span>
      }
      return null;
    }
  
    renderContent() {
      if(this.state.collapsed) {
        return null;
      }
      return (
      <div className="card-content">
        {this.props.content}
      </div>);
    }
  
    render() {
      return(
        <div id={this.props.id} className="card">
          <div className="card-title" onClick={this.state.canCollapse ? () => this.toggleCollapse() : null}>
            <span className="text-primary card-title-text">{this.props.title}</span>
            {this.renderCollapse()}
          </div>
          {this.renderContent()}
  
        </div>
      );
    }
  }