import { Component } from 'react';
import './App.css';
import { Card } from './components/Card';
import { Timeline } from './components/Timeline';
import profileImage from './ressources/kevingerspacher.png';

class App extends Component {
  about() {
    return(
      <p>Experienced Software Developer with focus for web applications build with Low Code and React. I'm working as Expert Software Engineer at Zühlke Switzerland.</p>
    );
  }

  projects() {
    const items = [];
    items.push({
      "identifier": "proj-1",
      "title": "InsuTrack",
      "text": "Sometimes it's hard to remember the last time insulin was administered. This simple PWA helps to create a history of the latest admissions. The user simply has to push the button up on admission to save the date and time of the latest admission.",
      "url": "https://insutrack.grsp.ch"
    });

    return items;
  }

  experience() {
    const items = [];
    items.push({
      "identifier": "exp-5",
      "title": "2024 @Zühlke Switzerland",
      "text": "Expert Low Code Engineer & People Lead (ad interim)"
    });
    items.push({
      "identifier": "exp-4",
      "title": "2023 @Zühlke Switzerland",
      "text": "Expert Software Engineer"
    });
    items.push({
      "identifier": "exp-3",
      "title": "2021 @Zühlke Switzerland",
      "text": "Advanced Software Engineer"
    });
    items.push({
      "identifier": "exp-2",
      "title": "2019 @Zühlke Switzerland",
      "text": "Professional Software Engineer"
    });
    items.push({
      "identifier": "exp-1",
      "title": "2016 @ABB Turbocharging Switzerland",
      "text": "Software Engineer (Internship & Student)"
    });

    return items;
  }

  certificates() {
    const items = [];
    items.push({
      "identifier": "cert-3",
      "title": "Mendix Advanced Developer",
      "text": "12.2021 | 31624"
    });
    items.push({
      "identifier": "cert-2",
      "title": "Mendix Intermediate Developer",
      "text": "12.2021 | 31398"
    });
    items.push({
      "identifier": "cert-1",
      "title": "Mendix Rapid Developer",
      "text": "04.2020 | 20878"
    });

    return items;
  }

  contact() {
    return (
      <div className="row">
        <div className="col center">
          <p className="text-bold">Mail</p>
          <a href="mailto:hello@grsp.ch">hello@grsp.ch</a>
        </div>
        <div className="col center">
          <p className="text-bold">LinkedIn</p>
          <a href="https://www.linkedin.com/in/kevin-gerspacher/">Kevin Gerspacher</a>
        </div>
        
        
      </div>
    );
  }

  render() {
    return(
      <div className="App">
        <div className="content">
          <img className="profile" src={profileImage} alt="Bild von Kevin Gerspacher"/>
          <h1 className="text-primary">Kevin Gerspacher</h1>
          <p>Software Engineer & Low Code Expert</p>
          <Card id="about" title="About" content={this.about()} canCollapse={false}/>
          <Card id="experience" title="Experience" content={<Timeline items={this.experience()}/>} canCollapse={true} initialCollapsed={false}/>
          <Card id="projects" title="Projects" content={<Timeline items={this.projects()}/>} canCollapse={true} initialCollapsed={false}/>
          <Card id="certificates" title="Certificates" content={<Timeline items={this.certificates()}/>} canCollapse={true} initialCollapsed={true}/>
          <Card id="contact" title="Contact" content={this.contact()} canCollapse={false}/>
        </div>
        
      </div>
    );
  }
}

export default App;