import { Component } from 'react';

export class Timeline extends Component {
    renderLink(item) {
        if("url" in item) {
            return <div className="row">
                <div className="col center">
                <button className="timeline-link" onClick={() => window.open(item.url)}>Open {item.title}</button>
                </div>
                </div>
        }
        return null;
    }

    render() {
      return(
        this.props.items.map(item => {
          return (
            <div key={item.identifier} className="timeline-item">
                <div className="timeline-content">
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                  {this.renderLink(item)}
                </div>
              </div>
          );
        })
      );
    }
  }